import { parseISO } from "date-fns";
import he from "he";
import React from "react";
import { CourseProgressEnum, QuizTypeEnum } from "__generated__/globalTypes";
import { Tooltip } from "components/common/Tooltip";
import {
  CoreCourseFieldsEvent as Event,
  CoreCourseFieldsQuizzes as Quiz,
} from "components/courses/MyCourses/__generated__/MyCourses";
import { hasEventEnded } from "components/events/utils";
import { TIME_EXPIRED_EXPLANATION } from "constants/site";

export enum EventState {
  NONE,
  AWAITING_COMPLETION,
  AWAITING_READINESS,
}

interface CourseFields {
  isWebinarRecordingAvailable: boolean | null;
}

interface CourseExpiryFields {
  timeExpiresForUserOn?: UpliftDateTime | null;
  timeExpiresForUserSubscriptionOn?: UpliftDateTime | null;
  isTimeExpiredForUser?: boolean;
  courseProgress: CourseProgressEnum;
}

interface CourseBasicFields extends CourseExpiryFields {
  isStarted: boolean;
  startedOn: UpliftDateTime | null;
  completedOn: UpliftDateTime | null;
}

interface Course extends CourseBasicFields {
  quizzes?: Quiz[];
  event?: Event | null;
  courseFields?: CourseFields | null;
}

interface CourseForEvent {
  courseFields?: CourseFields | null;
}

interface EnrollmentDetails {
  courseStatusText: string;
  buttonText: string;
  messageText: JSX.Element | string | null;
  posttestPercentage?: number | null;
  eventState: EventState;
}

export const getEnrollmentDetailsForEvent = (
  course: CourseForEvent | null,
  event: Omit<Event, "slug"> | null | undefined
): EnrollmentDetails | null => {
  const eventEndTime = event?.timestamps.end;

  if (event && eventEndTime) {
    const hasParticipation = event?.webinarDetails?.hasParticipationPulled;
    const userAttended = event?.userEventDetails?.didAttend;
    const isRecordingAvailable = course?.courseFields?.isWebinarRecordingAvailable;
    const notAvailableYet = "Not Available Yet";

    if (!hasEventEnded(event)) {
      return {
        courseStatusText: notAvailableYet,
        buttonText: "View Webinar",
        messageText: "This course will become available once the webinar has ended.",
        eventState: EventState.AWAITING_COMPLETION,
      };
    }

    if (!userAttended && !isRecordingAvailable) {
      if (!hasParticipation) {
        return {
          courseStatusText: notAvailableYet,
          buttonText: "Available Shortly",
          messageText: "This course will become available shortly for webinar attendees.",
          eventState: EventState.AWAITING_READINESS,
        };
      }

      return {
        courseStatusText: notAvailableYet,
        buttonText: "Awaiting Recording",
        messageText:
          "You will be notified by email in the coming days once the webinar recording is uploaded.",
        eventState: EventState.AWAITING_READINESS,
      };
    }
  }

  return null;
};

export const getExpiresText = (
  courseOrGroup: CourseExpiryFields,
  isGroup = false
): JSX.Element | null => {
  const timeExpiresForUserOn =
    courseOrGroup.timeExpiresForUserOn && parseISO(courseOrGroup.timeExpiresForUserOn);

  if (
    courseOrGroup.isTimeExpiredForUser ||
    !timeExpiresForUserOn ||
    courseOrGroup.courseProgress === CourseProgressEnum.COMPLETED
  ) {
    return null;
  }

  const isSubscriptionEndDate =
    courseOrGroup.timeExpiresForUserOn === courseOrGroup.timeExpiresForUserSubscriptionOn;
  const renewalText = isSubscriptionEndDate ? " unless subscription renewed" : "";

  return (
    <span>
      {isGroup ? `These courses expire` : `This course expires`}{" "}
      {timeExpiresForUserOn.toLocaleDateString("en-US")}
      {renewalText}.
    </span>
  );
};

export const getExpiredText = (
  courseOrGroup: CourseExpiryFields,
  isGroup = false
): JSX.Element | null => {
  const timeExpiresForUserOn =
    courseOrGroup.timeExpiresForUserOn && parseISO(courseOrGroup.timeExpiresForUserOn);

  if (!courseOrGroup.isTimeExpiredForUser || !timeExpiresForUserOn) {
    return null;
  }

  const explanation = courseOrGroup.isTimeExpiredForUser ? TIME_EXPIRED_EXPLANATION : "";

  return (
    <span>
      <Tooltip text={explanation} id="expiry-explanation">
        {isGroup ? `The following courses expired` : `This course expired`}{" "}
        {timeExpiresForUserOn.toLocaleDateString("en-US")}. {}
      </Tooltip>
    </span>
  );
};

export const getMessageTextForCourse = (course: CourseBasicFields): JSX.Element | null => {
  const expiredText = getExpiredText(course);

  if (!course.isStarted) {
    return expiredText;
  }

  if (course.courseProgress === CourseProgressEnum.COMPLETED) {
    const completedOnDate = (course.completedOn && parseISO(course.completedOn)) || new Date();

    return <span>You completed this course on {completedOnDate.toLocaleDateString("en-US")}.</span>;
  }

  const startedOnDate = (course.startedOn && parseISO(course.startedOn)) || new Date();

  return (
    <>
      <span>You started this course on {startedOnDate.toLocaleDateString("en-US")}.</span>{" "}
      {expiredText}
    </>
  );
};

export const getEnrollmentDetailsForCourse = (course: Course): EnrollmentDetails => {
  if (course.courseProgress !== CourseProgressEnum.COMPLETED) {
    const detailsForEvent = getEnrollmentDetailsForEvent(course, course.event);

    if (detailsForEvent) {
      return detailsForEvent;
    }
  }

  const messageText = getMessageTextForCourse(course);

  if (!course.isStarted) {
    return {
      courseStatusText: "Not Started",
      buttonText: "Start",
      messageText,
      eventState: EventState.NONE,
    };
  }

  if (course.courseProgress === CourseProgressEnum.COMPLETED) {
    const postTestQuiz = course.quizzes?.filter(quiz => quiz.type === QuizTypeEnum.POSTTEST)[0];

    return {
      courseStatusText: "Complete",
      buttonText: "Review & Access Certificate",
      messageText,
      posttestPercentage: postTestQuiz?.percentage,
      eventState: EventState.NONE,
    };
  }

  return {
    courseStatusText: "In Progress",
    buttonText: "Continue",
    messageText,
    eventState: EventState.NONE,
  };
};

export interface CourseAuthor {
  id: string | null;
  name: string | null;
  slug: string | null;
  courseAuthorFields: {
    title: string | null;
  } | null;
}

export const getAuthorNameWithTitle = (author?: CourseAuthor | null) => {
  if (!author || !author.name) return "";
  const name = he.decode(author.name);
  return author.courseAuthorFields?.title
    ? `${name}, ${author.courseAuthorFields.title}`
    : author.name;
};
