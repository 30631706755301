import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import { makeUrl } from "@uplift-ltd/strings";
import React, { Fragment, useState } from "react";
import { CourseProgressEnum } from "__generated__/globalTypes";
import MyAccountSidebar from "components/MyAccountSidebar";
import profileStyles from "components/Profile.module.css";
import Alert from "components/common/Alert";
import Container from "components/common/Container";
import Link from "components/common/Link";
import Loading, { FullPageLoading } from "components/common/Loading";
import SectionHeader from "components/common/SectionHeader";
import { TIME_EXPIRED_COURSES_WORDING } from "constants/site";
import { COURSE_CATALOG_URL, PROFILE_URL } from "constants/urls";
import { doesUserHaveLicenseData } from "helpers/user";
import { useTypedAssertUserContext } from "hooks/useUserContext";
import Course from "./Course";
import CourseGroup from "./CourseGroup";
import {
  MyCoursesQueryCourseGroupInlineFragment as EnrolledCourseGroup,
  MyCoursesQueryCourseInlineFragment as EnrolledCourse,
  MyCoursesQueryQuery as MyCoursesQuery,
} from "./__generated__/MyCourses";
import styles from "./MyCourses.module.css";

export const MY_COURSES_QUERY = gql`
  query MyCoursesQuery($progressTypes: [CourseProgressEnum!]!) {
    myCourses(progressTypes: $progressTypes) {
      id
      progress
      totalCourses
      courses {
        ... on Course {
          id
          ...CoreCourseFields
        }
        ... on CourseGroup {
          id
          title
          hasCertificate
          isTimeExpiredForUser
          timeExpiresForUserOn
          timeExpiresForUserSubscriptionOn
          ceUnits
          courseGroupFields {
            programCode
          }
          myCourses {
            id
            ...CoreCourseFields
          }
          courseGroupFields {
            resources {
              id
              uri
              title
            }
            webinarTrainingsBundle {
              id
              eventFields {
                resources {
                  id
                  uri
                  title
                }
              }
            }
          }
        }
      }
    }
  }
  fragment CoreCourseFields on Course {
    id
    slug
    title
    ceUnits
    isStarted
    startedOn
    completedOn
    courseProgress
    isExpired
    isTimeExpiredForUser
    timeExpiresForUserOn
    timeExpiresForUserSubscriptionOn
    hasCertificate
    quizzes {
      id
      percentage
      type
    }
    courseFields {
      isWebinarRecordingAvailable
      programCode
    }
    event {
      id
      slug
      webinarDetails {
        hasParticipationPulled
      }
      userEventDetails {
        didAttend
      }
      timestamps {
        end
      }
    }
  }
`;

interface ProgressGroupProps {
  coursesAndGroups: (EnrolledCourse | EnrolledCourseGroup)[];
  totalItems: number;
  progress: CourseProgressEnum;
  initiallyCollapsed?: boolean;
  title: string;
  description?: string;
  refetch?: (type: CourseProgressEnum) => void;
  refetching?: boolean;
}

const ProgressGroup = ({
  coursesAndGroups,
  totalItems,
  progress,
  initiallyCollapsed = false,
  title,
  description,
  refetch,
  refetching,
}: ProgressGroupProps) => {
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);
  const expandHandler = () => {
    if (refetch && isCollapsed) {
      refetch(progress);
    }

    setIsCollapsed(prev => !prev);
  };

  return (
    <div className="mb-2">
      <button
        className="flex items-center bg-zur-light mb-2 py-2 px-4 rounded-tr-lg rounded-tl-lg text-xl w-full gap-2"
        onClick={expandHandler}
        type="button"
      >
        <div className="grow text-left">
          {title} ({totalItems})
        </div>
        <div>{isCollapsed ? <span>show</span> : <span>hide</span>}</div>
        {isCollapsed ? (
          <ChevronDownIcon className="h-5 w-5" />
        ) : (
          <ChevronUpIcon className="h-5 w-5" />
        )}
      </button>

      <Transition show={!isCollapsed} as={Fragment}>
        <div className="overflow-hidden">
          {description && <p className={styles.sectionWording}>{description}</p>}
          {coursesAndGroups.length ? (
            <>
              {coursesAndGroups.map(courseOrGroup => {
                return (
                  <div key={courseOrGroup.id}>
                    {courseOrGroup.__typename === "Course" && <Course course={courseOrGroup} />}
                    {courseOrGroup.__typename === "CourseGroup" && (
                      <CourseGroup group={courseOrGroup} progress={progress} />
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {refetching ? (
                <div className="mb-20">
                  <Loading />
                </div>
              ) : (
                <p className={styles.sectionWording}>No items here.</p>
              )}
            </>
          )}
        </div>
      </Transition>
    </div>
  );
};

const MyCourses = () => {
  const currentUser = useTypedAssertUserContext();

  if (!currentUser) {
    throw new Error("Failed to load user");
  }

  const userHasLicenseData = doesUserHaveLicenseData(currentUser);

  const { data, loading, refetch, initialLoading } = useEnhancedQuery<MyCoursesQuery>(
    MY_COURSES_QUERY,
    {
      skip: !currentUser,
      variables: {
        progressTypes: [CourseProgressEnum.OVERVIEW, CourseProgressEnum.CONTENT],
      },
      refetchWritePolicy: "merge",
      notifyOnNetworkStatusChange: true,
    }
  );

  const refetching = loading && !initialLoading;

  if (initialLoading) {
    return <FullPageLoading />;
  }

  const refetchHandler = async (type: CourseProgressEnum) => {
    refetch({ progressTypes: [type] });
  };

  const notStarted = data?.myCourses?.find(
    progressGroup => progressGroup?.progress === CourseProgressEnum.OVERVIEW
  );

  const inProgress = data?.myCourses?.find(
    progressGroup => progressGroup?.progress === CourseProgressEnum.CONTENT
  );

  const complete = data?.myCourses?.find(
    progressGroup => progressGroup?.progress === CourseProgressEnum.COMPLETED
  );

  const expired = data?.myCourses?.find(
    progressGroup => progressGroup?.progress === CourseProgressEnum.TIME_EXPIRED
  );

  const totalCourses =
    (notStarted?.totalCourses || 0) +
    (inProgress?.totalCourses || 0) +
    (complete?.totalCourses || 0) +
    (expired?.totalCourses || 0);

  const isCollapsedNotStarted = !!notStarted?.totalCourses;
  const isCollapsedInProgress = !!inProgress?.totalCourses;

  return (
    <Container>
      <div className={profileStyles.root}>
        <div className={profileStyles.contentContainer}>
          <Alert className="mb-8 text-left" theme="info">
            You have 3 years to complete the courses from the date of purchase.
          </Alert>
          {!userHasLicenseData && (
            <Alert className="mb-8 text-left" theme="danger">
              It appears you have not yet verified your{" "}
              <Link href={PROFILE_URL}>Personal Profile</Link>. In order for the information on your
              CE certificate to be accurate, this must be done before completing a course.
            </Alert>
          )}
          <ProgressGroup
            coursesAndGroups={notStarted?.courses || []}
            totalItems={notStarted?.totalCourses || 0}
            progress={CourseProgressEnum.OVERVIEW}
            title="Not Started"
            initiallyCollapsed={!isCollapsedNotStarted}
          />
          <ProgressGroup
            coursesAndGroups={inProgress?.courses || []}
            totalItems={inProgress?.totalCourses || 0}
            progress={CourseProgressEnum.CONTENT}
            title="In Progress"
            initiallyCollapsed={!isCollapsedInProgress}
          />
          <ProgressGroup
            coursesAndGroups={complete?.courses || []}
            totalItems={complete?.totalCourses || 0}
            progress={CourseProgressEnum.COMPLETED}
            title="Completed"
            initiallyCollapsed
            refetch={refetchHandler}
            refetching={refetching}
          />
          <ProgressGroup
            coursesAndGroups={expired?.courses || []}
            totalItems={expired?.totalCourses || 0}
            progress={CourseProgressEnum.TIME_EXPIRED}
            title="Expired"
            description={TIME_EXPIRED_COURSES_WORDING}
            initiallyCollapsed
            refetch={refetchHandler}
            refetching={refetching}
          />
          {totalCourses === 0 && (
            <p className="text-center">
              You have not enrolled in any courses. Please check out our{" "}
              <Link href={makeUrl(COURSE_CATALOG_URL, { qualifier: "subjects" })}>
                course catalog.
              </Link>
            </p>
          )}
        </div>
        <div className={profileStyles.accountSidebarContainer}>
          <MyAccountSidebar activeItem="my-courses" />
        </div>
      </div>
    </Container>
  );
};

const MyCoursesPage = () => {
  return (
    <>
      <SectionHeader title="My Courses" />
      <MyCourses />
    </>
  );
};

export default MyCoursesPage;
