import {
  gql,
  MutationHookOptions,
  useEnhancedLazyQuery,
  useEnhancedMutation,
  useEnhancedQuery,
} from "@uplift-ltd/apollo";
import { useCallback, useState } from "react";
import { triggerAddToCartConversionTrackers } from "components/Cart/utils";
import { MY_COURSES_QUERY } from "components/courses/MyCourses/MyCourses";
import { CURRENT_USER_QUERY, useUserContext } from "hooks/useUserContext";
import {
  AddToCartMutation,
  AddToCartVariables,
  ApplyCouponMutation,
  ApplyCouponVariables,
  ApplyCreditsToCartMutation,
  ApplyCreditsToCartVariables,
  CartQueryQuery as CartQuery,
  CheckoutMutation,
  CheckoutVariables,
  EmptyCartGracefulMutation,
  PreCheckoutWithStripeMutation,
  PreCheckoutWithStripeVariables,
  RemoveCouponMutation,
  RemoveCouponVariables,
  RemoveCreditsFromCartMutation,
  RemoveCreditsFromCartVariables,
  RemoveFromCartMutation,
  RemoveFromCartVariables,
} from "./__generated__/queries";

export type { CartQuery };
export type { OrderStatusQuery, OrderStatusVariables } from "./__generated__/queries";

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

const CART_INFO_FRAGMENT = gql`
  fragment CartInfo on Cart {
    contents {
      nodes {
        key
        quantity
        subtotal
        total

        product {
          node {
            id
            sku
            name
            slug
            type
            priceUSD
            canPurchaseWithCredits
            creditCost
            hasPurchased

            bundledItems {
              id
              name
              slug
            }
          }
        }

        extraData {
          id
          key
          value
        }
      }
    }
    appliedCoupons {
      code
      discountAmount
    }
    productsWithAppliedCredits {
      id
      creditCost
    }
    total
    subtotal
    numericTotal
  }
`;

export const CART_QUERY = gql`
  ${CART_INFO_FRAGMENT}
  query CartQuery {
    cart(recalculateTotals: true) {
      ...CartInfo
    }
  }
`;

const APPLY_COUPON = gql`
  ${CART_INFO_FRAGMENT}
  mutation ApplyCoupon($code: String!) {
    applyCoupon(input: { code: $code }) {
      cart {
        ...CartInfo
      }
    }
  }
`;

const REMOVE_COUPON = gql`
  mutation RemoveCoupon($code: String!) {
    removeCoupons(input: { codes: [$code] }) {
      clientMutationId
    }
  }
`;

const ADD_TO_CART = gql`
  ${CART_INFO_FRAGMENT}
  mutation AddToCart($productId: Int!, $extraData: String) {
    addToCart(input: { productId: $productId, extraData: $extraData }) {
      cart {
        ...CartInfo
      }
    }
  }
`;

const REMOVE_FROM_CART = gql`
  ${CART_INFO_FRAGMENT}
  mutation RemoveFromCart($key: ID!) {
    removeItemsFromCart(input: { keys: [$key] }) {
      cart {
        ...CartInfo
      }
    }
  }
`;

const CHECKOUT = gql`
  mutation Checkout($paymentMethod: String!) {
    checkout(input: { paymentMethod: $paymentMethod }) {
      result
    }
  }
`;

const CHECKOUT_WITH_STRIPE = gql`
  mutation PreCheckoutWithStripe(
    $productId: String
    $productVariationId: String
    $couponCode: String
    $subscriptionId: ID
    $subscriptionProductFeatureId: ID
  ) {
    preCheckoutWithStripe(
      input: {
        productId: $productId
        productVariationId: $productVariationId
        couponCode: $couponCode
        subscriptionId: $subscriptionId
        subscriptionProductFeatureId: $subscriptionProductFeatureId
      }
    ) {
      success
      message
      errorCode
      clientSecret
    }
  }
`;

const APPLY_CREDITS_TO_CART = gql`
  ${CART_INFO_FRAGMENT}
  mutation ApplyCreditsToCart($key: ID!) {
    applyCreditsToCart(input: { key: $key }) {
      cart {
        ...CartInfo
      }
    }
  }
`;

const REMOVE_CREDITS_FROM_CART = gql`
  mutation RemoveCreditsFromCart($key: ID!) {
    removeCreditsFromCart(input: { key: $key }) {
      success
    }
  }
`;

const EMPTY_CART_GRACEFUL = gql`
  ${CART_INFO_FRAGMENT}
  mutation EmptyCartGraceful {
    emptyCartGraceful(input: {}) {
      cart {
        ...CartInfo
      }
    }
  }
`;

export const STRIPE_ORDER_STATUS = gql`
  query OrderStatus($paymentIntentClientSecret: String!) {
    orderStatus(paymentIntentClientSecret: $paymentIntentClientSecret) {
      isCompleted
      productIds
    }
  }
`;

const useUpdateCart = () => {
  const { updateQuery } = useEnhancedQuery<CartQuery>(CART_QUERY);

  return (data: CartQuery | null) => {
    updateQuery(prev => {
      if (data) {
        return data;
      }

      return prev;
    });
  };
};

export const useApplyCoupon = () => {
  const updateCart = useUpdateCart();

  const [applyCoupon] = useEnhancedMutation<ApplyCouponMutation, ApplyCouponVariables>(
    APPLY_COUPON,
    {
      onCompleted(data) {
        updateCart(data.applyCoupon);
      },
    }
  );

  return (code: string) => applyCoupon({ variables: { code: code.toLowerCase() } });
};

export const useRemoveCoupon = () => {
  const [removeCoupon] = useEnhancedMutation<RemoveCouponMutation, RemoveCouponVariables>(
    REMOVE_COUPON,
    {
      /**
       * There is a bug which prevents us from using the same logic as other mutations
       * When removing a coupon, the totals for the cart contents do not get updated,
       * so we need to perform a refetch in order to display the correct data
       *
       * @see https://github.com/wp-graphql/wp-graphql-woocommerce/issues/260
       */
      refetchQueries: [{ query: CART_QUERY }],
    }
  );

  return (code: string) => removeCoupon({ variables: { code } });
};

export const useAddToCartTracker = () => {
  const [addedProductIds, setAddedProductIds] = useState<number[]>([]);

  return (productId: number) => {
    if (!addedProductIds.includes(productId)) {
      addedProductIds.push(productId);
      setAddedProductIds(addedProductIds);
      triggerAddToCartConversionTrackers();
    }
  };
};

export const useAddToCart = () => {
  const updateCart = useUpdateCart();
  const addTracker = useAddToCartTracker();

  const [addToCart] = useEnhancedMutation<AddToCartMutation, AddToCartVariables>(ADD_TO_CART, {
    onCompleted(data) {
      updateCart(data.addToCart);
    },
  });

  return (productId: number, extraData?: string) => {
    const add = addToCart({ variables: { productId, extraData } });
    addTracker(productId);
    return add;
  };
};

export const useRemoveFromCart = () => {
  const { data } = useEnhancedQuery<CartQuery>(CART_QUERY);
  const updateCart = useUpdateCart();

  const [removeFromCart] = useEnhancedMutation<RemoveFromCartMutation, RemoveFromCartVariables>(
    REMOVE_FROM_CART,
    {
      onCompleted(newData) {
        updateCart(newData.removeItemsFromCart);
      },
    }
  );

  return useCallback(
    ({ id, key: providedKey }: RequireOnlyOne<{ id?: string; key?: string }, "id" | "key">) => {
      const key =
        providedKey || data?.cart?.contents?.nodes?.find(n => n?.product?.node?.id === id)?.key;

      if (key) {
        removeFromCart({ variables: { key } });
      }
    },
    [data?.cart?.contents?.nodes, removeFromCart]
  );
};

export const useEmptyCart = () => {
  const updateCart = useUpdateCart();

  const [emptyCart] = useEnhancedMutation<EmptyCartGracefulMutation>(EMPTY_CART_GRACEFUL, {
    onCompleted(data) {
      updateCart(data.emptyCartGraceful);
    },
  });

  return useCallback(() => emptyCart(), [emptyCart]);
};

export const useCartCredits = () => {
  const [applyCreditsToCart] = useEnhancedMutation<
    ApplyCreditsToCartMutation,
    ApplyCreditsToCartVariables
  >(APPLY_CREDITS_TO_CART, {
    /**
     * For some reason, the total price for the product whose credits were applied
     * isn't set in the response from this endpoint. Thus, we perform a refetch in
     * order to display the correct data
     */
    refetchQueries: [{ query: CART_QUERY }],
  });

  const [removeCreditsFromCart] = useEnhancedMutation<
    RemoveCreditsFromCartMutation,
    RemoveCreditsFromCartVariables
  >(REMOVE_CREDITS_FROM_CART, {
    /**
     * For some reason, the total price for the product whose credits were unapplied
     * isn't reset in the response from this endpoint. Thus, we perform a refetch in
     * order to display the correct data
     */
    refetchQueries: [{ query: CART_QUERY }],
  });

  return {
    applyCreditsToCart: (key: string) => applyCreditsToCart({ variables: { key } }),
    removeCreditsFromCart: (key: string) => removeCreditsFromCart({ variables: { key } }),
  };
};

export const useCheckout = (
  options: MutationHookOptions<CheckoutMutation, CheckoutVariables> = {}
) => {
  const { currentUser } = useUserContext();

  const [checkout] = useEnhancedMutation<CheckoutMutation, CheckoutVariables>(CHECKOUT, {
    ...options,
    refetchQueries: [
      { query: MY_COURSES_QUERY, variables: { userId: currentUser?.databaseId } },
      { query: CURRENT_USER_QUERY },
      { query: CART_QUERY },
    ],
  });

  return (paymentMethod: string) => checkout({ variables: { paymentMethod } });
};

export const usePreCheckoutWithStripe = (
  options: MutationHookOptions<PreCheckoutWithStripeMutation, PreCheckoutWithStripeVariables> = {}
) => {
  const [checkout] = useEnhancedMutation<
    PreCheckoutWithStripeMutation,
    PreCheckoutWithStripeVariables
  >(CHECKOUT_WITH_STRIPE, options);

  return useCallback(
    (
      productId?: string,
      productVariationId?: string | null,
      couponCode?: string | null,
      subscriptionId?: string | null,
      subscriptionProductFeatureId?: string | null
    ) =>
      checkout({
        variables: {
          productId,
          productVariationId,
          couponCode,
          subscriptionId,
          subscriptionProductFeatureId,
        },
      }),
    [checkout]
  );
};

export const usePostCheckoutWithStripe = () => {
  const [getMyDetails] = useEnhancedLazyQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const emptyCart = useEmptyCart();

  return useCallback(() => {
    getMyDetails();
    emptyCart();
  }, [emptyCart, getMyDetails]);
};
