import { SUBSCRIPTION_NAME } from "constants/site";

export type SubscriptionInterval = "MONTH" | "YEAR" | "UNKNOWN";

export type SubscriptionBilling = {
  interval: SubscriptionInterval;
  intervalCount: number;
  wording: string;
};

export function getBillingWording(interval: SubscriptionInterval, intervalCount: number): string {
  if (interval === "YEAR") {
    if (intervalCount > 1) {
      return `every ${intervalCount} years`;
    }
    return "per year";
  }
  if (interval === "MONTH") {
    if (intervalCount > 1) {
      return `every ${intervalCount} months`;
    }
    return "per month";
  }

  return "Unknown";
}

export function getSubscriptionName(name: string | null | undefined): string {
  return (name || "").includes(SUBSCRIPTION_NAME) ? name || "" : `${name} ${SUBSCRIPTION_NAME}`;
}
