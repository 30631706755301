import { gql } from "@uplift-ltd/apollo";
import {
  SubscriptionProductQueryProduct,
  SubscriptionUserQueryProduct,
} from "components/Subscriptions/__generated__/queries";

export const SUBSCRIPTION_PRODUCT_QUERY = gql`
  query SubscriptionProductQuery($id: ID!, $idType: ProductIdTypeEnum!) {
    product(id: $id, idType: $idType) {
      id
      databaseId
      name
      description
      priceUSD
      type
      seo {
        title
        metaDesc
        metaKeywords
        canonical
      }
      availability {
        isPurchasable
      }
      ... on VariableProduct {
        id
        variations {
          edges {
            node {
              id
              databaseId
              name
              price
              slug
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION_USER_QUERY = gql`
  query SubscriptionUserQuery($id: ID!, $idType: ProductIdTypeEnum!) {
    product(id: $id, idType: $idType) {
      id
      userIsSubscribed
      userSubscription {
        id
        accessFrom
        accessTo
        isAutoRenewing
        membersPages {
          id
          title
          slug
        }
      }
      couponForUser {
        code
        discountedPriceUSD
      }
    }
  }
`;

export type CombinedSubscriptionProduct = SubscriptionProductQueryProduct &
  SubscriptionUserQueryProduct;

export const STRIPE_CUSTOMER_PORTAL_URL_QUERY = gql`
  query StripeCustomerPortalUrl($returnUrl: String) {
    stripeCustomerPortalUrl(returnUrl: $returnUrl)
  }
`;

export const SUBSCRIPTION_PRODUCTS_QUERY = gql`
  query SubscriptionProductsQuery {
    subscriptionProducts {
      id
      name
      slug
      shortDescription
      prices {
        id
        price
        interval
        intervalCount
      }
    }
  }
`;
